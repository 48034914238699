<template>

<!-- Footer  -->

<footer class="optech-footer-section dark-bg optech-section-padding-top">
  <div class="container">
    <div class="optech-infobox-wrap extra-padding">
      <div class="row">
        <!-- <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="500">
          <a href="tel:123">
            <div class="optech-infobox-item">
              <div class="optech-infobox-icon">
                <i class="ri-phone-fill"></i>
              </div>
              <div class="optech-infobox-data">
                <p>Call anytime</p>
                <h5>518-564-3200</h5>
              </div>
            </div>
          </a>
        </div> -->
        <div class="col-xl-4 col-md-6" data-aos-duration="700">
          <a href="mailto:info@local-wifi.com">
            <div class="optech-infobox-item">
              <div class="optech-infobox-icon">
                <i class="ri-mail-fill"></i>
              </div>
              <div class="optech-infobox-data">
                <p>Email address</p>
                <h5>info@local-wifi.com</h5>
              </div>
            </div>
          </a>
        </div>
        <div class="col-xl-4 col-md-6" data-aos-duration="900">
          <div class="optech-infobox-item">
            <div class="optech-infobox-icon">
              <i class="ri-time-fill"></i>
            </div>
            <div class="optech-infobox-data">
              <p>Office Hours</p>
              <h5>10:00 AM - 11:30 PM</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="optech-footer-top optech-section-padding-bottom">
      <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-3">
          <div class="optech-footer-menu">
            <div class="optech-footer-title">
              <h5>Quick Links</h5>
            </div>
            <ul>
              <li><a href="about-us.html">About Us</a></li>
              <li><a href="team.html">Our Team</a></li>
              <li><a href="pricing.html">Pricing</a></li>
              <li><a href="blog.html">Blogs</a></li>
              <li><a href="contact-us.html">Contact Us</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-5">
          <div class="optech-footer-menu ml30">
            <div class="optech-footer-title">
              <h5>Services</h5>
            </div>
            <ul>
              <li><a href="">UI/UX Design</a></li>
              <li><a href="">App Development</a></li>
              <li><a href="">Digital Marketing</a></li>
              <li><a href="">Web Development</a></li>
              <li><a href="">Cyber Security</a></li>
            </ul>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4">
          <div class="optech-footer-menu">
            <div class="optech-footer-title">
              <h5>Information</h5>
            </div>
            <ul>
              <li><a href="">Working Process</a></li>
              <li><a href="">Privacy Policy</a></li>
              <li><a href="">Terms & Conditions</a></li>
              <li><a href="faq.html">Faqs</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <div class="optech-footer-bottom">
      <div class="row">
        <div class="col-lg-6 d-flex align-items-center">
          <div class="optech-copywright">
            <p> Copyright © 2025 Local WiFi. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
</template>