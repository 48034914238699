<template>
  <Navigation />
  <router-view :key="$route.fullPath" />
</template>

<style>
:root {
  --accent-bg: #FF4486 !important;
  --heading-color: #575757;
  --lw-brand: #FF4486;
  --lw-brand-hover: #FF005A;
  --lw-brand-text: #575757;
  --lw-brand-light: #FFCBDD;
  --mobile-menu-trigger-colour: white;
}

::selection {
  color: var(--lw-brand-text);
  background: var(--lw-brand-light);
}

.site-menu-main .sub-menu--item>a {
  color: var(--lw-brand-text) !important;
}

.site-menu-main .sub-menu--item>a .menu-item-text::after {
  background-color: var(--lw-brand) !important;
}

/* .slick-slide{
  width:220px !important;
} */

.optech-default-btn:hover {
  background-color: var(--lw-brand-hover);
}

.lw-inform {
  color: var(--lw-brand) !important;
}

.optech-iconbox-icon {
  background-color: var(--lw-brand-light) !important;
}

.optech-iconbox-wrap:hover .optech-iconbox-icon img {
  filter: none !important;
}

.optech-icon-btn {
  color: var(--lw-brand);
}

.optech-icon-btn:hover {
  color: var(--lw-brand-hover);
}

.optech-portfolio-thumb:before {
  background: linear-gradient(180deg, rgba(4, 13, 67, 0) 52.38%, var(--lw-brand-text) 91.91%) !important;
}

.optech-portfolio-thumb::after {
  background: linear-gradient(180deg, rgba(43, 77, 255, 0) 0%, var(--lw-brand) 100%) !important;
}

.optech-portfolio-btn {
  display: none;
}

.text-white {
  color: white !important;
}

.text-white>* {
  color: white !important;
}

.site-header .mobile-menu-trigger.light-color span {
  background-color: var(--mobile-menu-trigger-colour) !important;
}

.site-header .mobile-menu-trigger.light-color span::after,
.site-header .mobile-menu-trigger.light-color span::before {
  background-color: var(--mobile-menu-trigger-colour) !important;
}
</style>

<script>
import Navigation from './components/Global/Navigation.vue';
export default {
  components: {
    Navigation
  },
  watch: {
    $route(to, from) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }
  }
}
</script>