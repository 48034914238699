import { createRouter, createWebHashHistory } from 'vue-router'
import NotFound from '@/views/NotFound.vue'
import Home from '../views/Home.vue'
import Service from '@/views/Service.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path:'/networking-:type',
    component: Service,
  },

  {
    path:'/phone-:type',
    component: Service,
  },
  { path: '/404', component: NotFound },  
  { path: '/:catchAll(.*)', redirect: '/404' }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
