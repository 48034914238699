<template>

    <body>
        <div ref='breadcrumbimage' class="optech-breadcrumb"
            style="background-image: url(assets/images/breadcrumb/breadcrumb.png)">
            <div class="container">
                <h1 class="post__title">{{ serviceItem.title || '' }}</h1>
            </div>
        </div>

        <div class="section optech-section-padding">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="optech-service-details-wrap">
                            <div class="optech-service-details-item" v-for="serviceContent in serviceItem.content">
                                <h3>{{ serviceContent.title }}</h3>
                                <p v-for="paragraph in serviceContent.paragraphs">{{ paragraph }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="optech-service-sidebar">
                            <div class="optech-service-menu">
                                <ul>
                                    <li><a class="side-menu-title">Network Services</a></li>
                                    <li v-for="item in sideMenuItems.networking"><a :href="'#/'+item.url">{{ item.title }} <i class="ri-arrow-right-up-line"></i></a></li>
                                    <li><a class="side-menu-title">Phone Services</a></li>
                                    <li v-for="item in sideMenuItems.phone"><a :href="'#/'+item.url"> {{ item.title }} <i class="ri-arrow-right-up-line"></i></a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 
        <div class="section bg-cover optech-section-padding"
            style="background-image: url(assets/images/cta/cta-bg3.png)">
            <div class="container">
                <div class="optech-cta-wrap">
                    <div class="optech-cta-content center">
                        <h2>Let’s work together</h2>
                        <p>Each demo built with Teba will look different. You can customize anything appearance of your
                            website with only a few clicks</p>
                        <div class="optech-extra-mt aos-init aos-animate" data-aos="fade-up" data-aos-duration="600">
                            <a class="optech-default-btn optech-white-btn" href="contact-us.html"
                                data-text="Let’s Start a Project"> <span class="btn-wraper">Let’s Start a Project</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <Footer />
    </body>
</template>

<style>
.side-menu-title {
    background-color: white !important;
    color: var(--heading-color) !important;
    padding: 5px !important;
}
</style>

<script>
import Footer from '@/components/Global/Footer.vue';
import Services from '@/data/services.json';
import MenuNetworking from '@/data/menus/networking.json';
import MenuPhone from '@/data/menus/phone.json';
export default {
    components: {
        Footer
    },
    methods
        : {
        navigateTo(url) {
            window.location.href = window.location.origin + "/#/" + url;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant',
            }); window.location.reload();
        }
    },
    data() {
        return {
            serviceItem: [],
            baseCategory: 'networking',
            sideMenuItems: {
                networking: [...MenuNetworking],
                phone: [...MenuPhone]
            }
        }
    },

    mounted() {
        let serviceName = this.$route.path.split('/')[this.$route.path.split('/').length - 1];
        this.$refs.breadcrumbimage.style.backgroundImage = 'url(assets/images/breadcrumb/breadcrumb-' + serviceName + '-min.jpg)';
        this.serviceItem = Services[serviceName];
        this.baseCategory = serviceName.split('-')[0];
    }
}
</script>