<template>
    <div class="col-xl-4 col-md-6" data-aos="fade-up" :data-aos-duration="getAosDuration(position)">
        <div class="optech-iconbox-wrap style-two">
            <div class="optech-iconbox-icon">
                <img :src="'assets/images/lw-icons/'+icon+'.svg'" alt="">
            </div>
            <div class="optech-iconbox-data">
                <h5>{{title}} <br> <span class="lw-inform">{{type}}</span></h5>
                <p>{{description}}</p>
                <!-- <a class="optech-icon-btn" :href="uri"><i class="icon-show ri-arrow-right-line"></i> <span>Learn More</span> <i class="icon-hide ri-arrow-right-line"></i></a> -->
            </div>
        </div>
    </div>
</template>

<script>
export default{
    props: ['title', 'type', 'description', 'icon','uri', 'position'],
    methods: {
        getAosDuration: (position)=> {
            // Define the sequence values
            const sequence = [500, 700, 900];
            // Calculate the index within the sequence
            const index = position % sequence.length;
            // Return the corresponding value
            return sequence[index];
        }
    }
}
</script>