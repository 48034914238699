<template>

    <body>
        <div class="optech-errors-section">
            <div class="container">
                <div class="optech-errors-content">
                    <img src="assets/images/breadcrumb/errors404.svg" alt="">
                    <h2>Well then...</h2>
                    <p>'Twould seem the thing you are after isn't here... You can stay here as long as you need. Take a deep breath and relax. It's been a long day.<br />Time Spent Relaxing : <span id="hours">00</span>:<span id="minutes">00</span>:<span id="seconds">00</span></p>
                    <div>
                        <a class="optech-default-btn" href="/"> Back to Homepage </a>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>


<script>
import Navigation from '@/components/Global/Navigation.vue';
import Footer from '@/components/Global/Footer.vue';
export default {
    components: {
        Navigation,
        Footer
    },
    mounted() {
        var sec = -1;
        function pad(val) { return val > 9 ? val : "0" + val; }
        setInterval(function () {
            $("#seconds").html(pad(++sec % 60));
            $("#minutes").html(pad(parseInt(sec / 60, 10) % 60));
            $("#hours").html(pad(parseInt(sec / 3600, 10)));
        }, 1000);
    }
}
</script>