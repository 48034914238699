<template>
<body>

<div class="optech-preloader-wrap">
  <div class="optech-preloader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
<!-- End preloader -->

<!-- progress circle -->
<div class="paginacontainer">
  <div class="progress-wrap">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
    <div class="top-arrow">
      <i class="ri-arrow-up-s-line"></i>
    </div>
  </div>
</div>

<div class="optech-hero-section2 bg-cover" style="background-image: url(assets/images/hero/lw-hero-1-min.png)">
  <div class="container">
    <div class="optech-hero-content center">
      <div class="optech-hero-power">
        <img src="assets/images/wire.png">
      </div>
      <h1>The IT partner you dream about.</h1>
      <p>We transform businesses by providing innovative networking and VoIP solutions that evolve alongside you.</p>
      <div class="optech-extra-mt" data-aos="fade-up" data-aos-duration="800">
        <a class="optech-default-btn" href="https://forms.gle/xtB5J5FoeTjoKK4h9" target="_blank" data-text="Work With Us"><span class="btn-wraper">Work With Us</span></a>
      </div>
    </div>
  </div>
</div>
<!-- End section -->
 

<div class="section large-padding-tb4 overflow-hidden" style="padding-bottom: 130px !important;">
  <div class="container">
    <div class="row">
      <div class="col-lg-6">
        <div class="optech-thumb extra-mr">
          <img data-aos="fade-up" data-aos-duration="600" src="assets/images/sections/home/one/one.png" alt="">
          <div class="optech-thumb-position" data-aos="fade-up" data-aos-duration="800">
            <img src="assets/images/sections/home/one/two.png" alt="">
          </div>
          <div class="optech-shape1">
            <img src="assets/images/shape/shape1.svg" alt="">
          </div>
          <div class="optech-shape2">
            <img src="assets/images/shape/shape2.svg" alt="">
          </div>
        </div>
      </div>
      <div class="col-lg-6 d-flex align-items-center">
        <div class="optech-default-content ml40">
          <h2>We see that spark in you.</h2>
          <p>No matter the size or stage of your business, we know your ambitions are bold, and you're driven to push boundaries and achieve greatness.</p>
          <p> Every business—big or small—deserves enterprise-grade solutions with the reliability, security, and scalability to turn goals into reality.</p>
          <p>Let us empower you with the tools to break barriers and reach new heights with confidence.</p>
          <!-- <div class="optech-icon-list">
            <ul>
              <li><i class="ri-check-line"></i>Business Networking Solutions</li>
              <li><i class="ri-check-line"></i>On-Prem VoIP & Phone Systems</li>
              <li><i class="ri-check-line"></i>Cloud VoIP & Phone Systems</li>
              <li><i class="ri-check-line"></i>Bespoke Software Solutions</li>
            </ul>
          </div> -->
          <!-- TODO / ADD LINK TO OUR STORY -->
          <!-- <div class="optech-extra-mt">
            <a class="optech-default-btn" href="about-us.html" data-text="More About Us"><span class="btn-wraper">More About Us</span></a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End section -->
<div style="padding-bottom: 80px;"></div>
<div class="section overflow-hidden" style="padding-bottom: 130px;">
  <div class="container">
    <div class="row">
      <div class="optech-brand-section">
        <div class="optech-brand-title">
          <p>We work with industry leaders to make enterprise solutions accessible with any budget.</p>
        </div>
        <div class="optech-brand-slider">
          <div class="optech-brand-item" v-for="brand in brands" :key="brand+'_brand_key'">
            <img :src="'assets/images/lw-brand/brand_'+brand+'.png'" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section bg-light1 optech-section-padding2" style="padding-top: 90px !important;">
  <div class="container">
    <div class="optech-section-title center">
      <h2>Services to drive your success forward</h2>
    </div>
    <div class="row">
      <!-- Cloud VoIP -->

      <Home_ServiceItem v-for="(service, index) in services" 
        :key="service+'_service_key'"
        :title="service.title"
        :type="service.type"
        :description="service.description"
        :icon="service.icon"
        :uri="service.uri" 
        :position="index"
      />
    </div>
  </div>
</div>
<!-- End section -->

<div class="section optech-section-padding">
  <div class="container">
    <div class="optech-section-title center">
      <h2>Networking possibilities are endless</h2>
    </div>
  </div>
  <div class="optech-3column-slider" data-aos="fade-up" data-aos-duration="800">
    <!-- Network Item -->
    <div class="optech-portfolio-wrap mb-0" v-for="network in networkItems" :key="network+'_network_key'">
      <div class="optech-portfolio-thumb">
        <img :src="network.image" alt="">
        <a class="optech-portfolio-btn" :href="network.link">
          <span class="p-btn-wraper"><i class="ri-arrow-right-up-line" style="color:var(--lw-brand) !important;"></i></span>
        </a> 
        <div class="optech-portfolio-data">
          <a href="single-portfolio.html">
            <h4>{{network.title}}</h4>
          </a>
          <p>{{network.tag}}</p>
        </div>
      </div>
    </div>
    <!-- End Network Item -->
  </div>
</div>
<!-- End section -->

<div class="section bg-light1 optech-section-padding5">
  <div class="container">
    <div class="optech-section-title center" style="margin-bottom: 50px;">
      <h2>Working with us is simple.</h2>
    </div>
    <div class="row z-index">
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="500">
        <div class="optech-numberbox-wrap">
          <div class="optech-numberbox-icon">
            <img src="assets/images/v2/illustration1.svg" alt="">
          </div>
          <div class="optech-numberbox-data">
            <span>01</span>
            <h4>Initiation & Planning</h4>
            <p>Want to work with us? Awesome, Let's figure out exactly what you need. Nothing more, nothing less.</p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="700">
        <div class="optech-numberbox-wrap">
          <div class="optech-numberbox-icon">
            <img src="assets/images/v2/illustration2.svg" alt="">
          </div>
          <div class="optech-numberbox-data">
            <span>02</span>
            <h4>Execution & Development</h4>
            <p>Happy with what we have discussed? Even better! Let's get the ball rolling!</p>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="900">
        <div class="optech-numberbox-wrap">
          <div class="optech-numberbox-icon">
            <img src="assets/images/v2/illustration3.svg" alt="">
          </div>
          <div class="optech-numberbox-data">
            <span>03</span>
            <h4>Implementation & Maintenance</h4>
            <p>Now that everything is said and done, we aren't just going to leave you in the dark. We'll keep you and your systems up to date and running like they should be.</p>
          </div>
        </div>
      </div>
      <div class="optech-line">
        <img src="assets/images/v2/line.png" alt="">
      </div>
    </div>
  </div>
</div>
<!-- End section -->

<div class="section optech-section-padding2">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center">
        <div class="optech-default-content">
          <h2>Pricing</h2>
          <p>Far too often, we see companies that are too focused on the bottom line. Us? No, we're focused on your future.</p>
          <p>We take pride in our work and we'd rather see you succeed in whatever you do, than lose sleep worring about bills.</p>
        </div>
      </div>
    </div>
    <hr style="width:100%; height:5px; color:var(--lw-brand-text); margin-top: 25px !important; margin-bottom: 25px !important; opacity: 10%;"/>
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center">
        <div class="optech-default-content">
          <h2>Managed Networks</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <!-- Router Mgmt -->
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="500">
        <div class="optech-pricing-wrap text-white" style="background-color: #1a60da;">
          <div class="optech-pricing-header text-white">
            <h4>Router Management</h4>
            <p>The core of your network, used to manage all traffic and talk to the outside world.</p>
          </div>
          <div class="optech-pricing-price text-white">
            <h2>£30<span class="text-white">/Unit per month</span></h2>
          </div>
          <div class="optech-pricing-feature text-white">
            <ul class="text-white">
              <li><i class="text-white ri-check-line"></i>Fully Managed</li>
              <li><i class="text-white ri-check-line"></i>Monitored 24/7</li>
              <li><i class="text-white ri-check-line"></i>NOC Support</li>
              <li><i class="text-white ri-check-line"></i>Non Disruptive Updates</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End Router Mgmt -->
      <!-- Switch Mgmt -->
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="500">
        <div class="optech-pricing-wrap text-white" style="background-color: #242836;">
          <div class="optech-pricing-header text-white">
            <h4>Switch Management</h4>
            <p>Used to distribute your network throughout your site and power IoT devices.</p>
          </div>
          <div class="optech-pricing-price text-white">
            <h2>£15<span class="text-white">/Unit per month</span></h2>
          </div>
          <div class="optech-pricing-feature text-white">
            <ul class="text-white">
              <li><i class="text-white ri-check-line"></i>Fully Managed</li>
              <li><i class="text-white ri-check-line"></i>Monitored 24/7</li>
              <li><i class="text-white ri-check-line"></i>NOC Support</li>
              <li><i class="text-white ri-check-line"></i>Non Disruptive Updates</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End Switch Mgmt -->

      <!-- AP  Mgmt -->
      <div class="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="500">
        <div class="optech-pricing-wrap text-white" style="background-color: #ed2b37;">
          <div class="optech-pricing-header text-white">
            <h4>Access Point Management</h4>
            <p>Used to give guests, staff, tennants access to your network. Enabling a secure connection from anywhere on your site.</p>
          </div>
          <div class="optech-pricing-price text-white">
            <h2>£10<span class="text-white">/Unit per month</span></h2>
          </div>
          <div class="optech-pricing-feature text-white">
            <ul class="text-white">
              <li><i class="text-white ri-check-line"></i>Fully Managed</li>
              <li><i class="text-white ri-check-line"></i>Monitored 24/7</li>
              <li><i class="text-white ri-check-line"></i>NOC Support</li>
              <li><i class="text-white ri-check-line"></i>Non Disruptive Updates</li>
              <li><i class="text-white ri-check-line"></i>Multiple WiFi Networks</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- End AP Mgmt -->

    </div>
  </div>
</div>
<!-- End section -->

<!-- <div class="section optech-section-padding bg-light1">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 d-flex align-items-center">
        <div class="optech-default-content mr40">
          <h2>Let's build an awesome project together</h2>
          <p>Each demo built with Teba will look different. You can customize almost anything in the appearance of your website with only a few clicks. Each demo built with Teba will look different.</p>
          <div class="optech-contact-info-column">
            <div class="optech-contact-info">
              <i class="ri-map-pin-2-fill"></i>
              <h5>Address</h5>
              <p>1791 Yorkshire Circle Kitty<br>
              Hawk, NC 279499</p>
            </div>
            <div class="optech-contact-info">
              <i class="ri-mail-fill"></i>
              <h5>Contact</h5>
              <a href="mailto:name@email.com">info@mthemeus.com</a>
              <a href="tel:123">518-564-3200</a>
            </div>
          </div>
          Office Hours: Mon – Sat: 8:00 AM – 10:00 PM
        </div>
      </div>
      <div class="col-lg-6" data-aos="fade-up" data-aos-duration="600">
        <div class="optech-main-form bg-white ml60">
          <h3>Fill The Contact Form</h3>
          <p>Feel free to contact with us, we don’t spam your email</p>
          <form action="#">
            <div class="row">
              <div class="col-lg-6">
                <div class="optech-main-field">
                  <input type="text" placeholder="Your name">
                </div>
              </div>
              <div class="col-lg-6">
                <div class="optech-main-field">
                  <input type="number" placeholder="Phone number">
                </div>
              </div>
              <div class="col-lg-12">
                <div class="optech-main-field">
                  <input type="email" placeholder="Email address">
                </div>
              </div>
              <div class="col-lg-12">
                <div class="optech-main-field">
                  <textarea name="textarea" placeholder="Write your message"></textarea>
                </div>
              </div>
              <div class="col-lg-12">
                <button id="optech-main-form-btn" type="button" data-text="Send Message"><span class="btn-wraper">Send Message</span></button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->
<!-- End section -->

<Global_Footer />


</body>
</template>
<script>
import Home_ServiceItem from '@/components/Home/ServiceItem.vue'
import Global_Footer from '@/components/Global/Footer.vue'
export default {

  components: {
    Home_ServiceItem,
    Global_Footer
  },

  data(){
    return{
      networkItems: [
        {
          title:"Office Networks",
          tag:"Security, Remote Working",
          link:"",
          image:"assets/images/networkitems/office.png"
        },
        {
          title:"Hospatality Networks",
          tag:"Captive Networking",
          link:"",
          image:"assets/images/networkitems/hospatality.png"
        },
        {
          title:"Public Networks",
          tag:"High Capacity",
          link:"",
          image:"assets/images/networkitems/public.png"
        },
        {
          title:"MDU Networks",
          tag:"PPSK, Rate Limited",
          link:"",
          image:"assets/images/networkitems/mdu.png"
        },
        {
          title:"Conference Networks",
          tag:"High Capacity",
          link:"",
          image:"assets/images/networkitems/conference.png"
        },
        {
          title:"Event Networks",
          tag:"Security, High Capacity",
          link:"",
          image:"assets/images/networkitems/event.png"
        }
      ],
      services : [
        {
          title : "Business Networking",
          type : "Fully Managed",
          description: "High speed networking for internal operations.",
          icon: "server-connection",
          uri: "#",
        },
        {
          title : "Phone Systems",
          type : "Fully Managed",
          description: "Managed on-premise phone systems that scale.",
          icon: "headset",
          uri: "#",
        },
        {
          title : "{Bespoke Software}",
          type : "Service",
          description: "Have an idea? Let's build a solution that works for you.",
          icon: "code",
          uri: "#",
        }
      ],
      brands : [
        'aruba',
        'bt',
        'cisco',
        'fibrus',
        'grandstream',
        'ion',
        'mikrotik',
        'nomadix',
        'opengear',
        'poly',
        'tplink',
        'watchguard',
        'telnyx'
      ]
    }
  }
}
</script>